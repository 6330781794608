import isEqual from "fast-deep-equal";
import { PiniaPlugin, StateTree } from "pinia";
import { onScopeDispose } from "vue";

const PREFIX = "pinia-broadcast:";

interface StateSyncEvent {
  type: "sync";
  ts: number;
  value: StateTree;
}

interface StatePingEvent {
  type: "ping";
}

interface StatePongEvent {
  type: "pong";
}

type StateEvent = StateSyncEvent | StatePingEvent | StatePongEvent;

export interface BroadcastOptions<S extends StateTree> {
  /** Omit specific keys from being broadcasted */
  omit?: (keyof S)[];
}

declare module "pinia" {
  // eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
  export interface DefineStoreOptionsBase<S extends StateTree, Store> {
    broadcast?: BroadcastOptions<S>;
  }

  export interface PiniaCustomProperties {
    $broadcast: () => void;
  }
}

export const createPiniaBroadcast = (): PiniaPlugin => {
  return ({ store, options: { broadcast } }) => {
    if (!broadcast) {
      return;
    }

    const id = store.$id;
    const channel = new BroadcastChannel(PREFIX + id);

    let timestamp = -1;
    let snapshot: StateTree | undefined;
    let hasPeers = false;

    // Register listeners
    channel.onmessage = (ev) => {
      const event = ev.data as StateEvent;

      switch (event.type) {
        case "sync": {
          if (event.ts <= timestamp) {
            return;
          }

          hasPeers = true;
          timestamp = event.ts;

          if (isEqual(snapshot, event.value)) {
            return;
          }

          store.$patch((snapshot = event.value));
          return;
        }
        case "ping": {
          const pongEvent: StatePongEvent = {
            type: "pong",
          };

          hasPeers = true;
          channel.postMessage(pongEvent);
          return;
        }
        case "pong": {
          hasPeers = true;
          return;
        }
      }
    };

    onScopeDispose(() => {
      channel.close();
    });

    // Set broadcast functionality
    store.$broadcast = () => {
      if (!hasPeers) {
        return false;
      }

      const nextSnapshot = getStateSnapshot(store.$state, broadcast.omit);

      if (!isEqual(snapshot, nextSnapshot)) {
        const syncEvent: StateSyncEvent = {
          type: "sync",
          ts: (timestamp = getCurrentTime()),
          value: (snapshot = nextSnapshot),
        };

        channel.postMessage(syncEvent);
      }

      return true;
    };

    // Communicate to other tabs that we're here
    {
      const pingEvent: StatePingEvent = {
        type: "ping",
      };

      channel.postMessage(pingEvent);
    }

    // Now subscribe to the the store
    store.$subscribe((mutation) => {
      if (mutation.type === "direct") {
        store.$broadcast();
      }
    });
  };
};

// `Date.now()` can't be used as it doesn't guarantee monotonicity
const getCurrentTime = () => {
  return performance.timeOrigin + performance.now();
};

const getStateSnapshot = (
  state: StateTree,
  omitted?: (string | number | symbol)[]
): StateTree => {
  const cloned = JSON.parse(JSON.stringify(state));

  if (omitted) {
    for (let idx = 0, len = omitted.length; idx < len; idx++) {
      const key = omitted[idx];
      delete cloned[key];
    }
  }

  return cloned;
};
